// Import material theming functions
@use '@angular/material' as mat;
@import "../mat/pallete";

@include mat.core();

// Copy the palettes from your selected theme (usually theme.scss).
$app-primary: mat.define-palette($md-vdblue, 600);
$app-accent: mat.define-palette($md-vdgreen, 600);
$app-warn: mat.define-palette(mat.$red-palette);

// Create your Sass color vars (will be available in all the project)
$primary-color: mat.get-color-from-palette($app-primary);
$accent-color: mat.get-color-from-palette($app-accent);
$warn-color: mat.get-color-from-palette($app-warn);

$typography: mat.define-typography-config();
$density: 0;

$fv-light-theme: mat.define-light-theme((
        color: (
                primary: $app-primary,
                accent: $app-accent,
                warn: $app-warn,
        ),
        typography: $typography,
        density: $density,
));

$fv-dark-theme: mat.define-dark-theme((
        color: (
                primary: $app-primary,
                accent: $app-accent,
                warn: $app-warn,
        ),
        typography: $typography,
        density: $density,
));

@include mat.all-component-themes($fv-light-theme);

body {
  background: var(--white);
  color: var(--vd-gray-950);
  &.fv-dark {
    background: var(--vd-gray-950);
    color: var(--white);
    @include mat.all-component-colors($fv-dark-theme);
  }
}



