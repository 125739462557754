@use "@angular/material" as mat;

$sm-typography: mat.define-typography-config(
        $button: mat.define-typography-level(
                $font-size: 14px,
        ),
);
$sm-theme: mat.define-light-theme(
                (
                        typography: $sm-typography,
                        density: -1,
                )
);
.btn-sm {
  @include mat.button-density($sm-theme);
  @include mat.button-typography($sm-theme);
  @include mat.progress-spinner-density($sm-theme);
  @include mat.progress-spinner-typography($sm-theme);
}

$lg-typography: mat.define-typography-config(
        $button: mat.define-typography-level(
                $font-size: 18px,
        ),
);
$lg-theme: mat.define-light-theme(
                (
                        typography: $lg-typography,
                        density: 2,
                )
);
.btn-lg {
  @include mat.button-density($lg-theme);
  @include mat.button-typography($lg-theme);
  @include mat.progress-spinner-density($lg-theme);
  @include mat.progress-spinner-typography($lg-theme);
}
