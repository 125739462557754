//@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "libs/ui/src/assets/scss/_afw-colors.scss";

@tailwind base;

@layer base {

  *,
  ::before,
  ::after {
    border-style: unset;
  }

}

@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

:root {
  --primary-color: --vd-blue-600;
  --primary-light: #e7e1f6;
  --accent-color: #9A30C3;
  --warn-color: #C3303A;
  --green-color: #50c330;
  --white: #FFFFFF;
}

/* Virtue dynamics logo colors */
/* Bright Gray */
:root {
  --vd-gray-50: #f6f7f9;
  --vd-gray-100: #eceef2;
  --vd-gray-200: #d4dae3;
  --vd-gray-300: #aebbcb;
  --vd-gray-400: #8295ae;
  --vd-gray-500: #637994;
  --vd-gray-600: #4e617b;
  --vd-gray-700: #404e64;
  --vd-gray-800: #384354;
  --vd-gray-900: #2f3744;
  --vd-gray-950: #212630;
}

/* Pastel Green */
:root {
  --vd-green-50: #effbea;
  --vd-green-100: #dcf6d1;
  --vd-green-200: #bbeea8;
  --vd-green-300: #90e274;
  --vd-green-400: #78d65b;
  --vd-green-500: #4ab72b;
  --vd-green-600: #35921e;
  --vd-green-700: #2b6f1c;
  --vd-green-800: #26591b;
  --vd-green-900: #224c1b;
  --vd-green-950: #0e290a;
}

/* Eastern Blue */
:root {
  --vd-blue-50: #ebffff;
  --vd-blue-100: #cefeff;
  --vd-blue-200: #a3fafe;
  --vd-blue-300: #63f4fd;
  --vd-blue-400: #1ce4f4;
  --vd-blue-500: #00c7da;
  --vd-blue-600: #0398af;
  --vd-blue-700: #0a7e94;
  --vd-blue-800: #126578;
  --vd-blue-900: #145465;
  --vd-blue-950: #063846;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Change the border hover color */
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

html,
body {
  height: 100%;
}

.afw-container {
  display: block;
  max-width: 700px;
  margin: 0 auto;
}

.page-header {
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
}

.btn-row {
  display: flex;
}

.afw-card {
  width: 100%;
  border: 2px solid var(--vd-blue-500);
  border-radius: 11px;
  min-height: 89px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: unset;
  }

  .afw-card-body {

    .afw-card-title {
      font-size: 24px;
      margin-bottom: 15px;
      font-weight: 700;
    }
  }

  .afw-card-actions {
    margin-left: auto;
  }

  &:hover {
    background: rgba($vd-blue-900, .2);
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.d-grid {
  display: grid;
  gap: 12px;
}

.col-2 {
  grid-template-columns: 1fr 1fr;
}

.col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  mat-spinner {
    margin-bottom: 8px;
  }
}
.mat-spinner-white::ng-deep circle{
  stroke: #FFFFFF !important;
}